import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import { MDXRenderer } from "gatsby-plugin-mdx"

const TandcsPage = () => {

  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/terms-and-conditions/" }) {
        body
        frontmatter {
          path
          title
          hero {
            image
          }
        }
      }
      images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "assets/images"}}) {
        edges {
          node {
            childImageSharp {
              id
              fluid {
                originalName
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const {
    hero
  } = data.page.frontmatter

  const images = data.images.edges.map(data => data.node.childImageSharp.fluid)
  const getImage = name => images.find(({ originalName }) => originalName === name)

  return (
    <>
      <SEO title="Terms and Conditions" />

      <Hero image={getImage(hero.image)} />

      <div className="container markdown">
        <MDXRenderer>{data.page.body}</MDXRenderer>
      </div>
    </>
  )
}

export default TandcsPage